.carousel-wrapper { 
    padding: 25px;
}

.carousel-img { 
    height: 500px;
    background-color: rgb(58, 58, 58);
}

@media (max-width: 992px) {
    .carousel-wrapper { 
        padding: 15px;
    }
    
    .carousel-img { 
        height: 200px;
        background-color: rgb(58, 58, 58);
    }
}

.card-img-top {
  width: 100%;
  height: 30vh;
  object-fit: contain;
  background-color: rgb(58, 58, 58);
}

/* Dropzone */
.dropzone {
    border: 2px dashed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    transition: border 0.3s ease;
    position: relative;
    overflow: auto;
  }
  
.dropzone-file-list {
  margin-top: 10px;
  max-height: 100px; /* Adjust based on available space */
  overflow-y: auto;
  width: 100%;
}
  
.dropzone-file-list p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 0.9em;
  line-height: 1.2;
  word-wrap: break-word;
}
  
.dropzone-remove-file-button {
  background: none;
  border: none;
  /* color: #dc3545; */
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.dropzone-remove-file-button:hover {
  color: #c82333;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.sub-navbar {
  top: 58px;
  z-index: 10;
}
  
  
  








