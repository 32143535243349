body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures that #root takes at least 100% of the viewport height. */
}

main {
  padding-top: 70px;
  margin: 0 0 auto 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.margin-top-58 {
  margin-top: 58px;
}

.circular-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  z-index:2
}

.circular-container {
  width: 40px; 
  height: 40px;
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
}

input::placeholder {
  color: #9e9e9e !important; /* Light gray */
  font-style: italic; /* Optional */
}

hr.custom-divider {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

section {
  scroll-margin-top: 150px; 
}






