// Import Bootstrap's default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Override Bootstrap variables
$primary: #13294b;
$border-radius: 0;

//custom color to be mapped
$sponsify-blue: #13294b;
$sponsify-red: #ab2328;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary:    $primary,
  secondary:  $secondary,
  success:    $success,
  info:       $info,
  warning:    $warning,
  danger:     $danger,
  light:      $light,
  dark:       $dark,
// add any additional color below
  sponsify-blue: $sponsify-blue,
  sponsify-red: $sponsify-red
// now bg-accent,btn-accent,etc.. can be called
);

$enable-negative-margins: true;


// Import Bootstrap styles
@import "~bootstrap/scss/bootstrap";

// Custom styles to ensure zero border radius
.btn, 
.card, 
.input-group, 
.navbar, 
.alert {
  border-radius: 0 !important; // Apply to common components
}
